@import 'variable.scss';

// ***Media****
@media (max-width: 1460px) {
  .content {
    .user-info {
      width: 18%;
    }
  }
}

@media (max-width: 1200px) {
  // ***Sidenav***
  .content {
    .sidenav {
      min-width: 22%;
    }

    .user-info {
      width: 20%;
      left: 4%;
    }

    .main {
      padding: 0;

      .header {
        &.fixed-header {
          width: 78%;
        }

        .header-content {
          .header-left {
            h5 {
              font-size: 24px;
            }
          }
        }
      }

      .banner {
        .banner-box {
          .banner-content {
            max-height: 150px;

            .banner-content-left {
              padding: 20px 15px;
            }
          }
        }
      }

      .main-table {
        table {
          font-size: 14px;

          .action {
            .edit {
              margin-right: 5px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 992px) {
  // ***Sidenav***
  .content {
    .sidenav {
      min-width: 25%;
    }

    .user-info {
      width: 25%;
    }

    .main {
      .header {
        padding: 15px 0px;
        &.fixed-header {
          width: 76%;
        }
      }
      .ex-padding {
        padding: 0px;
      }
    }
  }
}

@media (max-width: 769px) {
  .content {
    #iconMenu {
      display: block;
    }

    .addblock {
      // display: block !important;
      transform: translateX(0px) !important;
    }

    .sidenav {
      min-width: 100%;
      min-height: 100%;
      transform: translateX(-1000px);
      z-index: 99999999;
      position: fixed;
      top: 0;
      left: 0;
      padding: 30px;
      height: 100vh !important;

      .sidenav-logo {
        #iconClose {
          display: block;
        }
      }

      .sidenav-menu {
        // padding: 20px 20px 20px 250px;
        .nav-link[data-toggle].collapsed:before {
          right: 430px;
        }

        .nav-link[data-toggle]:not(.collapsed):before {
          right: 430px;
        }
      }
    }

    .user-info {
      width: 30%;

      .user-info-box {
        .user-info-box-textbox {
          .user-info-box-textbox-text {
            p {
              font-size: 16px;
            }
          }
        }
      }
    }

    .main {
      .header {
        &.fixed-header {
          width: 100%;
        }

        .header-content {
          .header-left {
            h5 {
              font-size: 20px;
            }
          }
        }
      }
      .banner {
        .banner-box {
          .banner-content {
            max-height: 190px;
          }
        }
      }
    }
  }
}

@media (max-width: 630px) {
  .content {
    .sidenav {
      .sidenav-menu {
        .nav-link[data-toggle].collapsed:before {
          right: 350px;
        }

        .nav-link[data-toggle]:not(.collapsed):before {
          right: 350px;
        }
      }
    }
  }
}

@media (max-width: 576px) {
  .content {
    .user-info {
      width: 40%;
    }

    .sidenav {
      .sidenav-menu {
        .nav-link[data-toggle].collapsed:before {
          right: 260px;
        }

        .nav-link[data-toggle]:not(.collapsed):before {
          right: 260px;
        }
      }
    }

    .main {
      .banner {
        .banner-box {
          .banner-content {
            max-height: 150px;
          }
        }
      }
    }
  }
}

@media (max-width: 468px) {
  .content {
    .user-info {
      width: 50%;
    }

    .sidenav {
      .sidenav-menu {
        .nav-link[data-toggle].collapsed:before {
          right: 200px;
        }

        .nav-link[data-toggle]:not(.collapsed):before {
          right: 200px;
        }
      }
    }
  }
}

@media (max-width: 376px) {
  .content {
    .user-info {
      width: 55%;

      .user-info-box {
        .user-info-box-edit-user {
          width: 60px;
          height: 60px;
          left: 20px;

          .edit-link {
            bottom: 0px;

            img {
              width: 20px;
            }
          }
        }
      }
    }

    .sidenav {
      .sidenav-menu {
        .nav-link[data-toggle].collapsed:before {
          right: 100px;
        }

        .nav-link[data-toggle]:not(.collapsed):before {
          right: 100px;
        }
      }
    }

    .main {
      .header {
        .header-content {
          .header-left {
            h5 {
              font-size: 18px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 320px) {
}
