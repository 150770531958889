@import 'assets/css/variable.scss';

.content {
  .main {
    .main-content {
      display: flex;
      justify-content: space-between;
      @media (max-width: 1024px) {
        & {
          flex-direction: column;
        }
      }
      .from-row-box {
        display: flex;
        align-items: center;

        .form-row-left {
          width: 4%;

          input[type='radio'] {
            display: none;

            + label {
              color: #333;
              font-family: Arial, sans-serif;
              font-size: 14px;

              span {
                display: inline-block;
                width: 22px;
                height: 22px;
                line-height: 17px;
                vertical-align: middle;
                cursor: pointer;
                border-radius: 100%;
                background-repeat: no-repeat;
                background-position: center;
                text-align: center;
                background-color: $extraLightPowderBlue;
                border: 2px solid $lightPowederBlue;
                position: absolute;
                left: 0;
                top: 0;

                img {
                  opacity: 0;
                  transition: all 0.3s ease;
                  width: 10px;
                }
              }
            }

            &:checked + label span {
              background-color: $themeColor;
            }

            &:checked + label span img {
              opacity: 1;
            }
          }

          .custom-control-label {
            &::before {
              border-radius: 100%;
              height: 20px;
              width: 20px;

              // background-image: url(../images/check.svg);
              // background-color: $themeColor;
              // border: 1px solid $lightPowederBlue;
            }

            &::after {
              border-radius: 100%;
              height: 20px;
              width: 20px;

              // background-image: none;
              // background-color: $extraLightPowderBlue;
              border: 1px solid $lightPowederBlue;
            }
          }
        }

        .form-row-right {
          width: 96%;
        }
      }

      .main-content-left {
        // width: 60%;
        flex: 7;
        // overflow-y: scroll;

        &::-webkit-scrollbar {
          display: none;
        }

        .add-quiz {
          padding: 15px;

          .form {
            .form-group {
              .form-control {
                padding: 8px 15px;
                background-color: $extraLightPowderBlue;
                border: 1px solid $lightPowederBlue;
                border-radius: 10px;
                height: 45px;
                &:focus {
                  outline: transparent;
                  box-shadow: none;
                }
              }

              &.select-video {
                padding: 30px 15px;
                border: 2px dashed $lightPowederBlue;
                border-radius: 10px;

                // display: flex;
                // align-items: center;
                margin-bottom: 20px;
                text-align: center;

                .video-row {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                }

                label {
                  cursor: pointer;
                  margin: 0;
                  font-size: 14px;

                  svg {
                    margin-right: 5px;
                  }
                }

                #file-chosen {
                  margin-right: 5px;
                  font-size: 14px;
                  color: $colorDarkGray;
                }
              }

              &.edit-video {
                display: flex;
                justify-content: space-between;
                align-items: center;
                .edit-video-left {
                  display: flex;
                  align-items: center;
                  .edit-video-left-icon {
                    width: 90px;
                    height: 90px;
                    line-height: 90px;
                    border: 2px solid $lightPowederBlue;
                    border-radius: 10px;
                    text-align: center;
                    margin-right: 15px;
                    overflow: hidden;
                    svg {
                      color: $midBlue;
                      font-size: 20px;
                    }
                    img {
                      height: 100px;
                      object-fit: cover;
                    }
                  }
                  .edit-video-left-text {
                    .text-top {
                      span {
                        font-weight: 600;
                      }
                    }
                    .text-bottom {
                      display: flex;
                      align-items: center;
                      p {
                        font-size: 14px;
                        color: $colorDarkGray;
                      }
                    }
                  }
                }
                .edit-video-right {
                  svg {
                    color: $themeColor;
                  }
                }
              }

              input[type='range'] {
                -webkit-appearance: none;
                width: 100%;
                margin-top: 30px;

                &:focus {
                  outline: none;
                }

                &::-webkit-slider-runnable-track {
                  width: 100%;
                  height: 8px;
                  cursor: pointer;
                  // animate: 0.2s;
                  background: $lightPowederBlue;
                  border-radius: 25px;
                }

                &::-webkit-slider-thumb {
                  height: 20px;
                  width: 20px;
                  border-radius: 50%;
                  background: $midBlue;
                  border: 2px solid #fff;
                  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
                  cursor: pointer;
                  -webkit-appearance: none;
                  margin-top: -8px;
                }

                // &:focus::-webkit-slider-runnable-track {
                //     background: $midBlue;
                // }
              }

              .range-wrap {
                position: relative;
                margin: 0 auto 3rem;

                .range {
                  width: 100%;
                }

                .bubble {
                  background: $lightPowederBlue;
                  border: 2px solid #fff;
                  color: $midBlue;
                  padding: 0px 12px;
                  position: absolute;
                  border-radius: 5px;
                  height: 26;
                  width: 41px;
                  left: 50%;
                  top: -18px;
                  transform: translateX(-50%);
                  box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);

                  &::after {
                    content: '';
                    position: absolute;
                    width: 10px;
                    height: 10px;
                    border-left: 6px solid transparent;
                    border-right: 6px solid transparent;
                    border-top: 6px solid $lightPowederBlue;
                    bottom: -12px;
                    left: 40%;

                    // transform: rotate(45deg);
                  }
                }
              }
            }

            .textarea {
              font-weight: 600;
            }
          }
        }
      }

      .main-content-right {
        // width: 40%;
        flex: 4;

        height: 100%;
        padding: 30px;
        background-color: $midBlue;
        overflow-y: scroll;

        // &::-webkit-scrollbar {
        //   display: none;
        // }

        .quiz-que-box {
          display: inline-block;
          background-color: #fff;
          width: 100%;
          padding: 15px;
          border-radius: 10px;
          margin-bottom: 15px;

          .que {
            font-weight: 600;
            align-items: flex-start;

            .form-row-left {
              width: 6%;
            }

            .form-row-right {
              width: 90%;

              p {
                color: #000;
              }
            }
            .form-row-menu {
              text-align: right;

              a {
                display: inline-block;
                margin-bottom: 5px;
                img {
                  width: 25px;
                }
              }
            }
          }

          .from-row-box {
            .form-row-left {
              width: 6%;

              input[type='radio'] {
                + label {
                  span {
                    display: inline-block;
                    width: 20px;
                    height: 20px;
                    line-height: 15px;
                    top: 2px;

                    img {
                      opacity: 0;
                    }
                  }
                }

                &:checked + label span img {
                  opacity: 1;
                }
              }
            }

            .form-row-right {
              width: 94%;

              .q-text {
                color: $colorDarkGray;
              }
            }
          }
        }
      }
    }
  }
}

// ***Media****
@media (max-width: 1880px) {
  .content {
    .main {
      .main-content {
        .main-content-right {
          height: auto;
        }
      }
    }
  }
}

@media (max-width: 1400px) {
}

@media (max-width: 1200px) {
  .content {
    .main {
      .main-content {
        .from-row-box {
          .form-row-left {
            width: 7%;
          }

          .form-row-right {
            width: 93%;
          }
        }

        .main-content-right {
          padding: 15px;
          .quiz-que-box {
            .from-row-box {
              .form-row-left {
                width: 10%;
              }

              .form-row-right {
                width: 90%;
              }
            }

            .que {
              .from-row-box {
                .form-row-left {
                  width: 10%;
                }

                .form-row-right {
                  width: 86%;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 992px) {
  .content {
    .main {
      .main-content {
        .from-row-box {
          .form-row-left {
            width: 10%;
          }

          .form-row-right {
            width: 90%;
          }
        }

        .main-content-right {
          .quiz-que-box {
            .from-row-box {
              .form-row-left {
                width: 14%;
              }

              .form-row-right {
                width: 86%;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 769px) {
  .content {
    .main {
      .main-content {
        display: block;

        .from-row-box {
          .form-row-left {
            width: 5%;
          }

          .form-row-right {
            width: 95%;
          }
        }

        .main-content-left {
          width: 100%;
        }

        .main-content-right {
          width: 100%;

          .quiz-que-box {
            .from-row-box {
              .form-row-left {
                width: 5%;
              }

              .form-row-right {
                width: 95%;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 576px) {
  .content {
    .main {
      .main-content {
        .from-row-box {
          .form-row-left {
            width: 8%;
          }

          .form-row-right {
            width: 92%;
          }
        }

        .main-content-left {
          .add-quiz {
            form {
              .form-group {
                .form-control {
                  padding: 5px 15px;
                  font-size: 14px;
                }

                &.select-video {
                  padding: 20px 15px;
                }
              }
            }
          }
        }

        .main-content-right {
          .quiz-que-box {
            p {
              font-size: 14px;
            }

            .from-row-box {
              .form-row-left {
                width: 8%;
              }

              .form-row-right {
                width: 92%;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 468px) {
}

@media (max-width: 375px) {
  .content {
    .main {
      .main-content {
        .from-row-box {
          .form-row-left {
            width: 10%;
          }

          .form-row-right {
            width: 90%;
          }
        }

        .main-content-left {
          width: 100%;
        }

        .main-content-right {
          width: 100%;

          .quiz-que-box {
            .from-row-box {
              .form-row-left {
                width: 10%;
              }

              .form-row-right {
                width: 90%;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 320px) {
  .content {
    .main {
      .main-content {
        .main-content-left {
          .add-quiz {
            form {
              .form-group {
                &.select-video {
                  .video-row {
                    display: block;

                    .btn-filled {
                      width: 50%;
                      margin: 10px auto 0px auto;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
