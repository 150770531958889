@import 'assets/css/variable.scss';

.select-icon {
  padding: 30px 15px;
  border: 2px dashed $lightPowederBlue;
  border-radius: 10px;
  margin-bottom: 20px;
  text-align: center;

  .icon-row {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  label {
    cursor: pointer;
    margin: 0;
    font-size: 14px;

    svg {
      margin-right: 5px;
    }
  }

  #file-chosen {
    margin-right: 5px;
    font-size: 14px;
    color: $colorDarkGray;
  }
}
.edit-icon {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .edit-icon-left {
    display: flex;
    align-items: center;
    .edit-icon-left-icon {
      width: 90px;
      height: 90px;
      line-height: 90px;
      border: 2px solid $lightPowederBlue;
      border-radius: 10px;
      text-align: center;
      margin-right: 15px;
      overflow: hidden;
      svg {
        color: $midBlue;
        font-size: 20px;
      }
      img {
        height: 100px;
        object-fit: cover;
      }
    }
    .edit-icon-left-text {
      .text-top {
        span {
          font-weight: 600;
        }
      }
      .text-bottom {
        display: flex;
        align-items: center;
        p {
          font-size: 14px;
          color: $colorDarkGray;
        }
      }
    }
  }
  .edit-icon-right {
    svg {
      color: $themeColor;
    }
  }
}
.menu-editor-wrapper {
  background-color: #f9fdff;
  border: 2px solid #e9f9ff;
  display: flex;
  flex-direction: column-reverse;
  border-radius: 0 0 10px 10px;

  .menu-editor {
    min-height: 50vh;
  }

  .toolbar {
    border-top: 2px solid #e9f9ff;
    margin-bottom: 0;
    background-color: #a7e3f9;
    border-radius: 0 0 10px 10px;
    .inline-tool div {
      background-color: #a7e3f9;
      border: none;
      color: #0142da;
      &[aria-selected='true'] {
        font-weight: 900;
      }
    }
    .list-tool div {
      background-color: #a7e3f9;
      border: none;
      color: #0142da;
      &[aria-selected='true'] {
        font-weight: 900;
      }
    }
    .textAlign-tool div {
      background-color: #a7e3f9;
      border: none;
      color: #0142da;
      &[aria-selected='true'] {
        font-weight: 900;
      }
    }
    .blockType-tool {
      background-color: #a7e3f9;
    }
  }
}

.text-red {
  color: red;
}
