@import 'assets/css/variable.scss';

.menu {
  .menu-box {
    position: relative;
    margin-bottom: 20px;

    .menu-box-img {
      padding-left: 0;
      overflow: hidden;
      border-radius: 20px;
      border: 2px solid $lightPowederBlue;

      .menu-box-top {
        background-color: $extraLightPowderBlue;
        &.add-padding {
          padding: 15px 15px 50px 15px;
        }
        &.remove-padding {
          padding: 15px 15px 15px 15px;
        }
        .menu-box-text {
          &.detail {
            display: flex;
            align-items: center;
          }
          .menu-image {
            height: 50px;
            width: 50px;
            color: #b0c4de;
            margin-right: 10px;
          }
          .menu-icon {
            font-size: 50px;
            margin-right: 10px;
            color: #b0c4de;
          }
          span {
            font-weight: 700;
            font-size: 16px;
            display: inline-block;
          }

          p {
            font-size: 16px;
          }
        }
      }
    }

    .menu-box-profiles {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px;
      background-color: #fff;
      position: absolute;
      width: 85%;
      box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      bottom: -30px;
      left: 7.5%;

      .menu-box-profiles-left {
        ul {
          display: flex;
          align-items: center;
          li {
            width: 40px;
            height: 40px;
            line-height: 35px;
            border-radius: 100%;
            background-color: $colorYellow;
            overflow: hidden;
            text-align: center;
            border: 2px solid #fff;

            &.ex-ml {
              margin-left: -14px;
            }
            img {
              // height: 50px;
              object-fit: cover;
            }
            div {
              font-size: 14px;
            }
          }
        }
      }
      .menu-box-profiles-right {
        button {
          &:hover {
            background-color: #ffc400;
            color: #000;
          }
        }
      }
    }
  }
}

@media (max-width: 1440px) {
  .content {
    .main {
      .menu {
        .menu-box {
          .menu-box-img {
            .custom-control-label {
              &::after {
                left: 90%;
              }
              &::before {
                left: 90%;
              }
            }
          }
          .menu-box-profiles {
            .menu-box-profiles-left {
              ul {
                li {
                  &.ex-ml {
                    margin-left: -22px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  .content {
    .main {
      .menu {
        .menu-box {
          .menu-box-profiles {
            .menu-box-profiles-left {
              ul {
                li {
                  &.ex-ml {
                    margin-left: -13px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 992px) {
  .content {
    .main {
      .menu {
        .menu-box {
          .menu-box-profiles {
            .menu-box-profiles-left {
              ul {
                li {
                  &.ex-ml {
                    margin-left: -22px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 576px) {
  .content {
    .main {
      .menu {
        .menu-box {
          .menu-box-img {
            .menu-box-top {
              .menu-box-text {
                p {
                  font-size: 14px;
                }
              }
            }
          }
        }
      }
    }
  }
}
