@import '../../assets/css/variable.scss';

.quiz-bank {
  .quiz-bank-box {
    position: relative;
    margin-bottom: 20px;
    // border: 2px solid $lightPowederBlue;

    .quiz-bank-box-img {
      padding-left: 0;
      overflow: hidden;
      border-radius: 20px;

      .custom-control-label {
        width: 100%;
        &::before {
          border-radius: 100%;
          height: 20px;
          width: 20px;
          border: 1px solid $lightPowederBlue;
          left: 92%;
          // top: 15%;
          // background-color: $themeColor;
        }

        &::after {
          border-radius: 100%;
          height: 20px;
          width: 20px;
          // top: 15%;
          left: 92%;
          background-color: $lightPowederBlue;
          color: #000;
        }
      }
      .custom-control-input:checked ~ .custom-control-label::after {
        background-color: $themeColor;
      }

      .quiz-bank-box-top {
        padding: 15px 15px 50px 15px;
        background-color: $extraLightPowderBlue;
        border: 2px solid $lightPowederBlue;
        &.add-padding {
          padding: 15px 15px 50px 15px;
        }
        &.remove-padding {
          padding: 15px 15px 15px 15px;
        }
        .quiz-bank-box-text {
          span {
            font-weight: 700;
            font-size: 16px;
            margin-bottom: 10px;
            display: inline-block;
          }

          p {
            font-size: 16px;
          }
        }
      }
    }
    .quiz-bank-box-profiles {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px;
      background-color: #fff;
      position: absolute;
      width: 85%;
      box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      bottom: -30px;
      left: 7.5%;

      .quiz-bank-box-profiles-left {
        ul {
          display: flex;
          align-items: center;
          li {
            width: 40px;
            height: 40px;
            line-height: 35px;
            border-radius: 100%;
            background-color: $colorYellow;
            overflow: hidden;
            text-align: center;
            border: 2px solid #fff;

            &.ex-ml {
              margin-left: -14px;
            }
            img {
              // height: 50px;
              object-fit: cover;
            }
            div {
              font-size: 14px;
            }
          }
        }
      }
      .quiz-bank-box-profiles-right {
        button {
          &:hover {
            background-color: #ffc400;
            color: #000;
          }
        }
      }
    }
  }
}

@media (max-width: 1440px) {
  .content {
    .main {
      .quiz-bank {
        .quiz-bank-box {
          .quiz-bank-box-img {
            .custom-control-label {
              &::after {
                left: 90%;
              }
              &::before {
                left: 90%;
              }
            }
          }
          .quiz-bank-box-profiles {
            .quiz-bank-box-profiles-left {
              ul {
                li {
                  &.ex-ml {
                    margin-left: -22px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  .content {
    .main {
      .quiz-bank {
        .quiz-bank-box {
          .quiz-bank-box-profiles {
            .quiz-bank-box-profiles-left {
              ul {
                li {
                  &.ex-ml {
                    margin-left: -13px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 992px) {
  .content {
    .main {
      .quiz-bank {
        .quiz-bank-box {
          .quiz-bank-box-profiles {
            .quiz-bank-box-profiles-left {
              ul {
                li {
                  &.ex-ml {
                    margin-left: -22px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 576px) {
  .content {
    .main {
      .quiz-bank {
        .quiz-bank-box {
          .quiz-bank-box-img {
            .quiz-bank-box-top {
              .quiz-bank-box-text {
                p {
                  font-size: 14px;
                }
              }
            }
          }
        }
      }
    }
  }
}
