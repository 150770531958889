@import 'assets/css/variable.scss';

.content {
  .main {
    .customer-profile {
      .customer-profile-box {
        background-color: #f4fcff;
        padding: 60px 30px 30px 30px;
        margin: 0 auto;
        border-radius: 10px;
        border: 1px solid $lightPowederBlue;
        position: relative;
        width: 50%;

        .customer-profile-box-img {
          position: absolute;
          top: -15%;
          left: 50%;
          transform: translate(-50%, 50%);
          border-radius: 100%;

          img {
            width: 130px;
            height: 130px;
            border-radius: 100%;
          }
        }
        .customer-profile-box-name {
          margin-bottom: 20px;
          margin-top: 25px;
          text-align: center;

          h5 {
            font-weight: 700;
          }
        }
        .customer-profile-box-info {
          .info-value {
            &.add-padding {
              padding-left: 10%;
            }
          }
          .info-title {
            display: flex;
            align-items: center;
            &.add-padding {
              // padding-left: 40%;
            }
            img,
            svg {
              margin-right: 8px;
              width: 14px;
              color: $themeColor;
            }
            span {
              font-weight: 700;
            }
          }
          p {
            color: $colorDarkGray;
          }
        }
      }
    }
  }
}

// ***Media****
@media (max-width: 1400px) {
}
@media (max-width: 1200px) {
  .content {
    .main {
      .customer-profile {
        margin-top: 50px;
        .customer-profile-box {
          width: 100%;
        }
      }
    }
  }
}
@media (max-width: 992px) {
}
@media (max-width: 768px) {
  .content {
    .main {
      .customer-profile {
        margin-top: 50px;
        .customer-profile-box {
          padding: 80px 15px 15px 15px;
          width: 100%;
        }
      }
    }
  }
}
@media (max-width: 576px) {
  .content {
    .main {
      .customer-profile {
        margin-top: 50px;
        .customer-profile-box {
          padding: 60px 15px 15px 15px;
          width: 100%;
          .customer-profile-box-img {
            .img-box {
              img {
                width: 100px;
              }
            }
          }
          .customer-profile-box-info {
            .info-title {
              img,
              svg {
                margin-right: 5px;
              }
            }
            span,
            p {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 468px) {
}
@media (max-width: 375px) {
}
@media (max-width: 320px) {
}
