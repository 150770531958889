@import 'assets/css/variable.scss';

.login-page-container {
  background-color: rgba(1, 66, 218, 1);
  background-image: url(../../assets/images/OtherpageBG.png);
  background-attachment: fixed;
  background-position: center;
  background-size: contain;
  vertical-align: middle;
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;

  .get-in-touch {
    margin: 150px auto;
    position: relative;

    .get-in-touch-title {
      text-align: center;
      margin-bottom: 25px;
    }

    .contact-form {
      .form-body {
        width: 25%;
        margin: 0 auto;
        background-color: #fff;
        padding: 50px 20px;
        border-radius: 25px;

        hr {
          margin: 20px 0px;
        }
        .form-field-error {
          font-size: small;
          color: red;
          padding-left: 1.6rem;
        }
        .form-field {
          display: flex;
          align-items: center;
          position: relative;
          margin: 0px 0;

          .form-field-icon {
            svg {
              display: inline-block;
              color: $themeColor;
            }
          }

          .form-field-body {
            margin-left: 15px;
            width: 100%;

            .input-text {
              display: block;
              width: 100%;
              height: 36px;
              border-color: transparent;
              font-size: 16px;
              line-height: 26px;
              font-weight: 400;

              &:focus {
                outline: none;
              }
            }

            .label {
              position: absolute;
              left: 25px;
              bottom: 0px;
              font-size: 14px;
              line-height: 26px;
              font-weight: 500;
              color: $colorLightGray;
              cursor: text;
              transition: transform 0.2s ease-in-out;
              text-transform: uppercase;
            }

            .input-text:focus + .label,
            .input-text:not([value='']) + .label {
              font-size: 11px;
              color: $colorLightGray;
              transform: translateY(-20px);
            }
          }
          .form-field-eye {
            svg {
              color: $colorLightGray;
            }
          }
        }
      }

      .form-footer {
        display: block;
        text-align: center;
        .forgot-link {
          width: 100%;
          display: inline-block;
          margin: 15px 0px;
          color: #fff;

          &:hover {
            color: $colorYellow;
          }
        }
        .submit-btn {
          margin: 0 auto;
          margin: 15px 0px;
          display: inline-block;
          font-size: 16px;
          font-weight: 500;
          border: none;
          cursor: pointer;
          width: 160px;

          &:hover {
            svg {
              transform: translateX(10px);
            }
          }

          svg {
            transition: $transition;
          }
        }
      }
    }
  }

  // ***Media****
  @media (max-width: 1400px) {
  }
  @media (max-width: 1200px) {
    .get-in-touch {
      .contact-form {
        .form-body {
          width: 40%;
        }
      }
    }
  }
  @media (max-width: 992px) {
  }
  @media (max-width: 768px) {
    .get-in-touch {
      .contact-form {
        .form-body {
          width: 50%;
        }
      }
    }
  }
  @media (max-width: 576px) {
    .get-in-touch {
      .contact-form {
        padding: 0px 15px;
        .form-body {
          width: 100%;
          padding: 30px 20px;
        }
      }
    }
  }
}
