@import 'assets/css/variable.scss';

.content {
  .main {
    .header {
      .header-content {
        .header-left {
          a {
            margin-right: 15px;

            svg {
              color: $themeColor;
            }
          }
        }

        .header-right {
          .header-status {
            display: flex;
            align-items: center;
            margin-right: 2rem;
            span {
              color: $colorDarkGray;
              margin-right: 0.5rem;
              &.status-active {
                color: #0142da;
              }
            }
            .toggle {
              margin-left: 5px;
              margin-top: 8px;
            }
            p {
              color: $themeColor;
              font-weight: 500;
            }
          }
          .header-action {
            padding: 0px 10px;
            display: flex;
            align-items: center;

            a,
            div {
              width: 45px;
              height: 45px;
              line-height: 45px;
              background-color: #000;
              border-radius: 100%;
              text-align: center;

              svg {
                color: #fff;
                font-size: 18px;
              }

              &.action-delete {
                background-color: $colorRed;
                margin-right: 8px;
                cursor: pointer;
              }
              &.action-edit {
                background-color: $themeColor;
                cursor: pointer;
              }
            }
          }
        }
      }
    }

    .detail-info {
      text-align: center;

      .detail-info-box {
        .detail-info-box-profile {
          margin: 0 auto;
          width: 150px;
          height: 150px;
          overflow: hidden;
          border-radius: 100%;

          &.img {
            background: none;
          }

          svg {
            color: #fff;
            font-size: 40px;
          }

          img {
            height: 100%;
            width: 100%;
            border-radius: 100%;
            object-fit: scale-down;
          }
        }

        .detail-info-box-title {
          margin: 20px 0px;
        }

        .detail-info-box-info {
          a {
            display: block;
            color: $colorDarkGray;
            font-size: 14px;

            &:hover {
              color: $themeColor;
            }

            span {
              color: $themeColor;
              margin-right: 5px;
            }
          }
        }
      }
    }

    .info-tab {
      .info-tab-box {
        border: 2px solid $lightPowederBlue;
        padding: 30px 15px 15px 15px;
        border-radius: 10px;
        text-align: center;
        position: relative;

        .info-tab-box-tabular {
          .btn-group {
            margin-right: 0px;
            box-shadow: none;
            text-align: center;
            position: absolute;
            top: -25px;
            left: 48%;

            .btn {
              margin-bottom: 0;
              padding: 0;
              border-radius: 100%;
              width: 40px;
              height: 40px;
              line-height: 33px;
              background-color: $colorYellow;

              &:focus {
                box-shadow: none;
                outline: none;
                border: none;
              }

              svg {
                margin-left: -4px;
                margin-bottom: -4px;
                width: 18px;
                height: 18px;
                color: black;
              }

              &.btn-off {
                border-radius: 100%;
                margin-left: -5px;

                &:hover {
                  z-index: 0;
                }

                svg {
                  color: #000;
                }

                &:focus {
                  box-shadow: none;
                  outline: none;
                  border: none;
                }
              }

              &.btn-on {
                border-radius: 100%;

                svg {
                  color: #000;
                }

                &:focus {
                  box-shadow: none;
                  outline: none;
                  border: none;
                }
              }

              &.btn-on.active {
                background-color: $themeColor;
                color: white;

                svg {
                  color: #fff;
                }

                &:focus {
                  box-shadow: none;
                  outline: none;
                  border: none;
                }
              }

              &.btn-off.active {
                background-color: $themeColor;
                color: white;

                svg {
                  color: #fff;
                }

                &:focus {
                  box-shadow: none;
                  outline: none;
                  border: none;
                }
              }
            }

            input {
              appearance: none;
            }
          }
        }

        .info-tab-box-detail {
          p {
            color: $colorDarkGray;
          }
        }
      }
    }
  }
}

// ***Media****
@media (max-width: 1400px) {
}

@media (max-width: 1200px) {
  .content {
    .main {
      .info-tab {
        .info-tab-box {
          margin: 0px;
        }
      }
    }
  }
}

@media (max-width: 992px) {
  .content {
    .main {
      .header {
        .header-content {
          .header-right {
            .header-action {
              a {
                width: 40px;
                height: 40px;
                line-height: 40px;
                svg {
                  font-size: 16px;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .content {
    .main {
      .header {
        .header-content {
          .header-left {
            a {
              img {
                width: 20px;
              }
            }
          }
        }
      }
      .detail-info {
        .detail-info-box {
          .detail-info-box-title {
            margin: 10px 0px;
          }
          .detail-info-box-profile {
            width: 100px;
            height: 100px;
          }
        }
      }

      .info-tab {
        .info-tab-box {
          .info-tab-box-tabular {
            .btn-group {
              left: 45%;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 576px) {
  .content {
    .main {
      .header {
        .header-content {
          .header-right {
            .header-status {
              font-size: 14px;
              span,
              p {
                display: none;
              }
              .toggle {
                margin-left: 00px;
                margin-right: -10px;
              }
            }
            .header-action {
              a {
                width: 32px;
                height: 32px;
                line-height: 30px;
                svg {
                  font-size: 14px;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 468px) {
  .content {
    .main {
      .info-tab {
        .info-tab-box {
          .info-tab-box-tabular {
            .btn-group {
              left: 40%;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 375px) {
  .content {
    .main {
      .header {
        .header-content {
          .header-left {
            a {
              img {
                width: 16px;
              }
            }
          }
        }
      }

      .info-tab {
        .info-tab-box {
          .info-tab-box-tabular {
            .btn-group {
              left: 37%;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 320px) {
  .content {
    .main {
      .header {
        .header-content {
          .header-right {
            .header-status {
              .toggle {
                margin-left: 00px;
                margin-right: -10px;
              }
            }
            .header-action {
              padding: 0px 3px;
              a {
                &.action-delete {
                  margin-right: 4px;
                }
              }
            }
          }
        }
      }
    }
  }
}
