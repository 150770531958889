@import 'assets/css/variable.scss';

.modal {
  .modal-content {
    border-radius: 15px !important;
    .modal-header {
      border-radius: 15px 15px 0 0 !important;
      background-color: $lightPowederBlue;
      // padding-bottom: 30px;
    }
    // .modal-body {
    // }
  }
}

.organization-modal {
  .quiz-que-box {
    display: inline-block;
    background-color: #fff;
    width: 100%;
    padding-bottom: 6px;
    padding-top: 4px;
    border-bottom: 1px solid #e9f9ff;

    .que {
      font-weight: 600;
      align-items: flex-start;

      .form-row-left {
        width: 6%;
      }

      .form-row-right {
        margin-bottom: 10px;
        font-size: 14px;

        p {
          color: #000;
        }
      }
      .form-row-menu {
        text-align: right;

        a {
          display: inline-block;
          margin-bottom: 5px;
          img {
            width: 25px;
          }
        }
      }
    }
    .from-row-box {
      display: flex;
      align-items: center;

      .form-row-left {
        width: 4%;

        input[type='radio'],
        input[type='checkbox'] {
          display: none;

          + label {
            color: #333;
            font-family: Arial, sans-serif;
            font-size: 14px;

            span {
              display: inline-block;
              width: 22px;
              height: 22px;
              line-height: 17px;
              vertical-align: middle;
              cursor: pointer;
              border-radius: 100%;
              background-repeat: no-repeat;
              background-position: center;
              text-align: center;
              background-color: $extraLightPowderBlue;
              border: 2px solid $lightPowederBlue;
              position: absolute;
              left: 0;
              top: 0;

              img {
                opacity: 0;
                transition: all 0.3s ease;
                width: 10px;
              }
            }
          }

          &:checked + label span {
            background-color: $themeColor;
          }

          &:checked + label span img {
            opacity: 1;
          }
        }

        .custom-control-label {
          &::before {
            border-radius: 100%;
            height: 20px;
            width: 20px;

            // background-image: url(../images/check.svg);
            // background-color: $themeColor;
            // border: 1px solid $lightPowederBlue;
          }

          &::after {
            border-radius: 100%;
            height: 20px;
            width: 20px;

            // background-image: none;
            // background-color: $extraLightPowderBlue;
            border: 1px solid $lightPowederBlue;
          }
        }
      }

      .form-row-right {
        width: 96%;
      }
    }
  }
}

.content {
  .main {
    .main-content {
      .sec-header {
        span {
          color: $themeColor;
          font-weight: 600;
          font-size: 18px;
        }
      }

      .main-content-left {
        overflow-y: scroll;
        height: 100vh;
        .video-box {
          .video-box-img {
            border-radius: 15px;
            overflow: hidden;
            width: 410px !important;
            height: 230px !important;
            position: relative;

            .video-img {
              width: auto !important;
              height: 230px !important;
              object-fit: fill;
              width: 100%;
            }

            .video-box-icon {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            }
          }

          .video-box-detail {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 5px;

            span {
              color: $colorDarkGray;
              font-size: 14px;
            }
          }

          .video-box-text {
            p {
              margin-top: 8px;
              font-weight: 500;
            }
          }
        }

        .organization-box {
          margin-top: 10px;

          .org-box {
            padding: 40px 10px 10px 10px;
            border: 1px solid $lightPowederBlue;
            background-color: $extraLightPowderBlue;
            border-radius: 10px;
            position: relative;
            display: inline-block;
            width: 100%;
            margin: 20px 0px;
            text-align: center;

            .org-box-icon {
              width: 55px;
              height: 55px;
              line-height: 35px;
              border-radius: 100%;
              padding: 10px;
              overflow: hidden;
              position: absolute;
              top: 0;
              left: 50%;
              transform: translate(-50%, -50%);
            }

            .org-box-title {
              position: relative;
              padding-bottom: 10px;
              margin-bottom: 5px;

              &::before {
                content: '';
                position: absolute;
                bottom: 0;
                left: 35%;
                height: 2px;
                width: 30%;
                background-color: $lightPowederBlue;
              }

              h6 {
                font-weight: 650;
              }
            }

            .org-box-detail {
              a {
                color: $colorDarkGray;
                font-size: 14px;
              }
            }
          }
        }
      }
      .main-content-right {
        overflow-y: scroll;
        overflow-x: auto;
        height: 100vh;
        // &::-webkit-scrollbar {
        //   -webkit-appearance: none;
        //   width: 6px;
        //   background-color: #f5f5f5;
        // }

        // &::-webkit-scrollbar-thumb {
        //   border-radius: 10px;
        //   background-color: #fefeff;
        // }

        // &::-webkit-scrollbar-track {
        //   border-radius: 10px;
        //   background-color: #bbe9f9;
        // }
      }
    }
  }
}

.org-modal {
  .modal-header {
    padding-bottom: 35px;
    .sec-header {
      text-align: center;
      width: 100%;
    }
    .close {
      width: 50px;
    }
  }
  .modal-body {
    padding: 2rem 2rem 1rem 2rem !important;
  }
  .modal-search {
    width: 90%;
    display: flex;
    position: absolute;
    top: -25px;
    .search_box {
      justify-content: center;
      width: 100%;
      border-radius: 40px !important;
      padding: 8px 15px;
      border: 1px solid #e9f9ff;
    }
  }
  .modal-footer {
    justify-content: center;
    border: none;
    padding-top: 0;
    button {
      width: 140px;
    }
  }
  .modal-backdrop.show {
    opacity: 0.9 !important;
  }
}

// ***Media****
@media (max-width: 1400px) {
}

@media (max-width: 1200px) {
  .content {
    .main {
      .main-content {
        .main-content-left {
          .video-box {
            .video-box-img {
              height: 190px;

              .video-img {
                height: 190px;
                width: 100%;
              }
            }
          }

          .organization-box {
            .org-box {
              .org-box-title {
                &::before {
                  left: 45%;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 992px) {
}

@media (max-width: 768px) {
  .main-content-left {
    height: auto !important;
  }
  .main-content-right {
    height: auto !important;
  }
}

@media (max-width: 576px) {
  .content {
    .main {
      .main-content {
        .main-content-left {
          .video-box {
            .video-box-text {
              p {
                font-size: 14px;
                margin-top: 0px;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 468px) {
  .content {
    .main {
      .main-content {
        .main-content-left {
          .organization-box {
            .btn-filled {
              padding: 0;
              width: 40px;
              height: 40px;

              span {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 375px) {
  .content {
    .main {
      .main-content {
        .main-content-left {
          .organization-box {
            .org-box {
              .org-box-title {
                &::before {
                  left: 42%;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 320px) {
}

@media (min-width: 769px) {
  .main-content-right {
    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 6px;
      background-color: #f5f5f5;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: #fefeff;
    }

    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: #bbe9f9;
    }
  }
}
