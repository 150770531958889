// **Transition***
$transition: all 0.3s ease 0s;

// ***Colors***
$themeColor: #0142da;
$colorLightGray: #b6c4cb;
$colorDarkGray: #838c8f;
$colorYellow: #ffc400;
$darkPowderBlue: #7bb7ce;
$midBlue: #a7e3f9;
$lightPowederBlue: #e9f9ff;
$extraLightPowderBlue: #f9fdff;
$colorRed: #f00b22;

// ***Common Css***
@mixin section {
  padding: 40px 0px;

  @media (max-width: 992px) {
    padding: 50px 0px;
  }

  @media (max-width: 768px) {
    padding: 30px 0px;
  }

  @media (max-width: 576px) {
  }
}

@mixin button {
  padding: 12px 20px;
  display: block;
  border: none;
  cursor: pointer;
  font-weight: 500;

  @media (max-width: 992px) {
    padding: 8px 20px;
  }

  @media (max-width: 576px) {
    font-size: 14px;
    padding: 5px 18px;
  }
}

@mixin text-xl {
  font-size: 28px;
  font-weight: 700;

  @media (max-width: 992px) {
    font-size: 24px;
  }

  @media (max-width: 768px) {
  }

  @media (max-width: 576px) {
  }
}

@mixin text-lg {
  font-size: 18px;
  font-weight: 600;
}

// ***Button***
@mixin btnFilled {
  background-color: $colorYellow;
  border-radius: 25px;
  color: #000;
  padding: 12px 20px;
  display: block;
  border: none;
  cursor: pointer;
  font-weight: 500;
  text-align: center;

  @media (max-width: 1400px) {
  }

  @media (max-width: 1200px) {
    padding: 8px 15px;
  }

  @media (max-width: 992px) {
  }

  @media (max-width: 768px) {
  }

  @media (max-width: 576px) {
    font-size: 14px;
    padding: 5px 18px;
  }
}

@mixin btnBordered {
  background-color: transparent;
  border: 1px solid $colorYellow;
  border-radius: 25px;
  color: #000;
  padding: 5px 18px;

  @media (max-width: 576px) {
    padding: 4px 15px;
    font-size: 14px;
  }

  @media (max-width: 468px) {
  }

  @media (max-width: 375px) {
  }

  @media (max-width: 320px) {
  }
}

// ***Section header***
@mixin sec-header {
  font-size: 20px;
  font-weight: 900;

  @media (max-width: 576px) {
    font-size: 18px;
  }

  @media (max-width: 468px) {
  }

  @media (max-width: 375px) {
  }

  @media (max-width: 320px) {
  }
}

// ***Toggle status***
@mixin toggle {
  .material-switch > input[type='checkbox'] {
    display: none;
  }

  .material-switch > label {
    cursor: pointer;
    height: 0px;
    position: relative;
    width: 40px;
  }

  .material-switch > label::before {
    background: $extraLightPowderBlue;
    border-radius: 8px;
    border: 2px solid $darkPowderBlue;
    content: '';
    height: 13px;
    margin-top: -8px;
    position: absolute;
    opacity: 0.3;
    transition: all 0.4s ease-in-out;
    width: 30px;
  }

  .material-switch > label::after {
    background: $darkPowderBlue;
    border-radius: 16px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
    content: '';
    height: 18px;
    width: 18px;
    left: -4px;
    margin-top: -7px;
    position: absolute;
    top: -4px;
    transition: all 0.3s ease-in-out;
  }

  .material-switch > input[type='checkbox']:checked + label::before {
    background: inherit;
    opacity: 0.5;
    border: 2px solid $themeColor;
  }

  .material-switch > input[type='checkbox']:checked + label::after {
    background: inherit;
    left: 13px;
    background-color: $themeColor;
  }
}

// ***Media****
// @media (max-width: 1400px) {}
// @media (max-width: 1200px) {}
// @media (max-width: 992px) {}
// @media (max-width: 768px) {}
// @media (max-width: 576px) {}
// @media (max-width: 468px) {}
// @media (max-width: 375px) {}
// @media (max-width: 320px) {}
