@import './variable.scss';

body {
  height: 100%;
}
.content {
  .sidenav {
    min-height: 100vh;
    height: auto;
  }

  .main {
    .filter-data-input-container {
      position: relative;

      input,
      select {
        border: 2px solid $lightPowederBlue;
        padding: 10px 15px 10px 45px;
        border-radius: 25px;
      }
    }

    .header {
      .header-left {
        position: relative;

        h5 {
          margin-right: 15px;
          span {
            color: $themeColor;
            font-size: 14px;
          }
        }

        .search {
          position: relative;

          .search-input-div {
            .search-input {
              border: 2px solid $lightPowederBlue;
              padding: 10px 15px 10px 45px;
              border-radius: 25px;
            }
          }

          .search-icon {
            position: absolute;
            top: 20%;
            left: 7%;

            svg {
              color: $themeColor;
            }
          }
        }
      }

      .header-right {
        .btn-group {
          margin-right: 15px;
          box-shadow: none;
          text-align: center;

          .btn {
            margin-bottom: 0;
            padding: 0;
            border-radius: 100%;
            width: 50px;
            height: 50px;
            line-height: 43px;
            border: 2px solid $lightPowederBlue;

            &:focus {
              box-shadow: none;
              outline: none;
              border: none;
            }

            svg {
              margin-left: -4px;
              margin-bottom: -4px;
              width: 20px;
              height: 20px;
              color: black;
            }

            &.btn-off {
              border-radius: 100%;
              width: 50px;
              height: 50px;
              margin-left: -10px;

              &:hover {
                z-index: 0;
              }

              svg {
                color: $themeColor;
              }

              &:focus {
                box-shadow: none;
                outline: none;
                border: none;
              }
            }

            &.btn-on {
              border-radius: 100%;
              width: 50px;
              height: 50px;

              svg {
                color: $themeColor;
              }

              &:focus {
                box-shadow: none;
                outline: none;
                border: none;
              }
            }

            &.btn-on.active {
              background-color: $themeColor;
              color: white;

              svg {
                color: #fff;
              }

              &:focus {
                box-shadow: none;
                outline: none;
                border: none;
              }
            }

            &.btn-off.active {
              background-color: $themeColor;
              color: white;

              svg {
                color: #fff;
              }

              &:focus {
                box-shadow: none;
                outline: none;
                border: none;
              }
            }
          }

          input {
            appearance: none;
          }
        }
      }
    }

    .main-table {
      // padding: 20px;

      .table-responsive {
        border-radius: 10px;
      }

      table {
        border-radius: 10px;
        th,
        td {
          vertical-align: baseline;
        }
        thead {
          color: #fff;
        }

        .col-blue {
          background-color: $darkPowderBlue;
        }

        .col-lightBlue {
          background-color: $lightPowederBlue;
        }

        .col-exlight {
          background-color: $extraLightPowderBlue;
        }

        .toggle {
          .material-switch > input[type='checkbox'] {
            display: none;
          }

          .material-switch > label {
            cursor: pointer;
            height: 0px;
            position: relative;
            width: 40px;
          }

          .material-switch > label::before {
            background: $extraLightPowderBlue;
            border-radius: 8px;
            border: 2px solid $darkPowderBlue;
            content: '';
            height: 13px;
            margin-top: -8px;
            position: absolute;
            opacity: 0.3;
            transition: all 0.4s ease-in-out;
            width: 30px;
          }

          .material-switch > label::after {
            background: $darkPowderBlue;
            border-radius: 16px;
            box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
            content: '';
            height: 18px;
            width: 18px;
            left: -4px;
            margin-top: -7px;
            position: absolute;
            top: -4px;
            transition: all 0.3s ease-in-out;
          }

          .material-switch > input[type='checkbox']:checked + label::before {
            background: inherit;
            opacity: 0.5;
            border: 2px solid $themeColor;
          }

          .material-switch > input[type='checkbox']:checked + label::after {
            background: inherit;
            left: 13px;
            background-color: $themeColor;
          }
        }

        .action {
          display: flex;
          align-items: center;
          // justify-content: space-around;

          .edit {
            background-color: #e6efff;
            padding: 5px;
            width: 30px;
            height: 30px;
            line-height: 20px;
            text-align: center;
            border-radius: 6px;

            svg {
              color: $themeColor;
            }
          }

          .delete {
            background-color: #ffecf4;
            padding: 5px;
            width: 30px;
            height: 30px;
            line-height: 20px;
            text-align: center;
            border-radius: 6px;

            svg {
              color: red;
            }
          }

          .view {
            background-color: #d2efd2;
            padding: 5px;
            width: 30px;
            height: 30px;
            line-height: 20px;
            text-align: center;
            border-radius: 6px;
            margin-right: 10px;

            svg {
              color: $themeColor;
            }
          }
        }
      }
    }

    .footer {
      padding: 20px;

      .list-per-page {
        display: flex;
        align-items: center;

        span {
          display: inline-block;
          margin-right: 10px;
          color: $colorLightGray;
          font-weight: 500;
        }

        .dropdown {
          .dropdown-toggle {
            background-color: $colorYellow;
            border: transparent;
            color: #000;
            min-height: 36px !important;

            &:focus {
              box-shadow: none;
            }

            .dropdown-item {
              background: #fff;
            }
          }

          .dropdown-menu {
            min-width: auto;
          }
        }
      }

      .pagination-content {
        nav {
          .pagination {
            align-items: center;
            .page-item {
              .page-link {
                border: 2px solid $lightPowederBlue;
                border-radius: 100%;
                height: 40px;
                width: 40px;
                padding: 0;
                // margin-right: 10px;
                text-align: center;
                line-height: 38px;
                color: #000;

                &:hover {
                  background-color: $extraLightPowderBlue;
                }

                &:focus {
                  outline: transparent;
                  box-shadow: none;
                }

                &.prev {
                  border: transparent;
                  color: $darkPowderBlue;
                  font-weight: 800;
                }

                &.next {
                  border: transparent;
                  color: $themeColor;
                  font-weight: 800;
                }

                &.active {
                  background-color: $darkPowderBlue;
                  color: #fff;
                }
              }
            }
          }
        }
      }

      .enter-page-num {
        span {
          display: inline-block;
          margin-right: 10px;
          color: $colorLightGray;
          font-weight: 500;
        }

        input {
          width: 60px;
          padding: 5px;
          border: 2px solid $lightPowederBlue;
          border-radius: 8px;
        }
      }
    }
  }
}

// ***Media****
@media (max-width: 1880px) {
  // .content {
  //   .sidenav {
  //     height: auto;
  //   }
  // }
}
@media (max-width: 1400px) {
  .sidenav {
    min-width: 20%;
  }
}

@media (max-width: 1200px) {
  .content {
    .main {
      .header {
        padding: 15px 0px;

        .header-content {
          .header-left {
            .search {
              .search-input-div {
                .search-input {
                  padding: 5px 10px 5px 35px;
                  font-size: 14px;
                }
              }

              .search-icon {
                top: 20%;
                left: 7%;

                svg {
                  width: 14px;
                }
              }
            }
          }

          .header-right {
            .btn-group {
              .btn {
                &.btn-on,
                &.btn-off {
                  width: 42px;
                  height: 42px;
                  line-height: 35px;

                  svg {
                    width: 14px;
                  }
                }
              }
            }
          }
        }
      }

      .main-table {
        padding: 15px 0px;
      }
    }
  }
}

@media (max-width: 992px) {
  .content {
    .main {
      .header {
        .header-content {
          .header-right {
            .btn-filled {
              font-size: 18px;
              width: 40px;
              height: 40px;
              padding: 0;
              line-height: 40px;

              span {
                display: none;
              }
            }
          }
        }
      }
      .footer {
        .list-per-page {
          justify-content: center;
        }
        .pagination-content {
          margin: 20px 0px;
        }
        .text-right {
          text-align: center !important;
        }
      }
    }
  }
}

@media (max-width: 768px) {
}

@media (max-width: 576px) {
  .content {
    .main {
      .header {
        .header-content {
          .header-left {
            h5 {
              margin-right: 8px;
            }
          }

          .header-right {
            .btn-group {
              margin-right: 8px;

              .btn {
                &.btn-on,
                &.btn-off {
                  width: 35px;
                  height: 35px;
                  line-height: 28px;

                  svg {
                    width: 12px;
                  }
                }
              }
            }

            .btn-filled {
              width: 32px;
              height: 32px;
              line-height: 32px;
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 468px) {
  .content {
    .main {
      .header {
        .header-content {
          .header-left {
            .search {
              .search-input-div {
                input {
                  display: none;
                }
              }
              .search-icon {
                position: relative;
                top: 0 !important;
                svg {
                  width: 16px;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 320px) {
  .content {
    .main {
      width: 105%;
    }
  }
}
