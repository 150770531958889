@import 'assets/css/variable.scss';

.content {
  .main {
    .main-content {
      .main-content-left {
        overflow-y: scroll;
        height: 100vh;
        .add-quiz {
          .edit-video {
            display: flex;
            justify-content: space-between;
            .edit-video-left {
              display: flex;
              align-items: center;
              .edit-video-left-icon {
                width: 90px;
                height: 90px;
                line-height: 90px;
                border: 2px solid $lightPowederBlue;
                border-radius: 10px;
                text-align: center;
                margin-right: 15px;
                overflow: hidden;
                svg {
                  color: $midBlue;
                  font-size: 20px;
                }
                img {
                  height: 100px;
                  object-fit: cover;
                }
              }
              .edit-video-left-text {
                .text-top {
                  span {
                    font-weight: 600;
                  }
                }
                .text-bottom {
                  display: flex;
                  align-items: center;
                  p {
                    font-size: 14px;
                    color: $colorDarkGray;
                  }
                }
              }
            }
            .edit-video-right {
              svg {
                color: $themeColor;
              }
            }
          }
          textarea {
            font-weight: 600;
          }
          .btn-filled {
            width: 200px;
          }
        }
      }

      .main-content-right {
        overflow-y: scroll;
        height: 100vh;
      }
    }
  }
}

@media (max-width: 576px) {
  .content {
    .main {
      .main-content {
        .main-content-left {
          .add-quiz {
            .edit-video {
              .edit-video-left {
                .edit-video-left-text {
                  font-size: 14px;

                  .text-bottom {
                    font-size: 12px;
                  }
                }
              }
            }
            .btn-filled {
              width: 150px;
            }
          }
        }
      }
    }
  }
}
