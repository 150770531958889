.user-info-box-textbox {
  .user-info-box-textbox-icon {
    position: relative;
    display: inline-block;

    .dropbtn {
      cursor: pointer;
    }

    hr {
      margin: 0;
    }

    .dropdown-content {
      position: absolute;
      background-color: #a7e3f9;
      min-width: 160px;
      box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
      z-index: 1;
      bottom: 25px;
      border-radius: 10px;
      left: 30px;
      padding-top: 7px;
      padding-bottom: 7px;
      box-sizing: border-box;
    }

    .dropdown-content .link {
      color: black;
      padding: 5px 16px;
      text-decoration: none;
      display: block;
      cursor: pointer;
      box-sizing: border-box;
    }

    .dropdown-content .link:hover {
      outline: 2px solid #6cafc7;
    }
  }
}
