@import 'assets/css/variable.scss';

.content {
  .main {
    .profile-status {
      .profile-status-img {
        width: 140px;
        height: 140px;
        border-radius: 100%;
        text-align: center;
        background: #83c5de;
        display: flex;
        align-items: center;
        justify-content: center;
        &.img {
          background: none;
        }
        svg {
          color: #fff;
          font-size: 40px;
        }

        img {
          height: 100%;
          width: 100%;
          border-radius: 100%;
          object-fit: scale-down;
        }
      }
      .profile-status-toggle {
        display: flex;
        align-items: center;
        font-size: 16px;
        .title {
          p {
            color: $colorDarkGray;
            font-weight: 500;
            margin-right: 12px;
          }
        }
        .toggle {
          margin-bottom: -9px;
        }
        .status {
          span {
            color: $themeColor;
            font-weight: 600;
          }
        }
      }
    }
    .add-form {
      .add-form-basic-form {
        .form-group {
          .form-control {
            background-color: $extraLightPowderBlue;
            border: 2px solid $lightPowederBlue;
            padding: 10px 15px;
            border-radius: 10px;
            color: $colorDarkGray;
            height: 45px;

            &:focus {
              box-shadow: none;
              outline: none;
              border: 2px solid $lightPowederBlue;
            }
          }
          .dropdown {
            .dropdown-toggle {
              text-align: left;
              position: relative;
              font-weight: normal;

              &::after {
                position: absolute;
                right: 15px;
                top: 45%;
              }
            }
            .dropdown-menu {
              width: 100%;
              border: 2px solid $lightPowederBlue;
            }
          }
        }
        .sec-header {
          font-weight: 600;
          margin-bottom: 20px;
        }
        .btn-filled {
          width: 200px;
        }
      }
    }
  }
}

@media (max-width: 992px) {
  .content {
    .main {
      .profile-status {
        .profile-status-toggle {
          justify-content: flex-end;
        }
      }
    }
  }
}
@media (max-width: 768px) {
  .content {
    .main {
      .add-form {
        .add-form-basic-form {
          .form-group {
            .form-control {
              padding: 8px 15px;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 576px) {
  .content {
    .main {
      .add-form {
        .add-form-basic-form {
          .form-group {
            .form-control {
              padding: 5px 15px;
              font-size: 14px;
            }
            .dropdown {
              .dropdown-menu {
                font-size: 14px;
              }
            }
          }
          .btn-filled {
            width: 150px;
          }
        }
      }
    }
  }
}
@media (max-width: 468px) {
  .content {
    .main {
      .profile-status {
        .profile-status-img {
          width: 80px;
          height: 80px;
          line-height: 90px;
          svg {
            font-size: 30px;
          }
        }
        .profile-status-toggle {
          font-size: 14px;
          .title {
            p {
              margin-right: 2px;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 375px) {
}
@media (max-width: 320px) {
}
